<template>
  <FocusTrap>
    <div  id="mycard" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 10px;" >
        <h5 v-if="voucher.id == 0" class="card-title">HO Payment Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  HO Payment Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Paid To</label>
              <select id="cmbledger" class="form-control select" autofocus required="" v-if="voucher" v-model="voucher.ledger_id" >
                <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-7">

          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Date</label>
              <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Amount</label>
              <input id="txtamount" type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00"   v-if="voucher" v-model="voucher.amount"/>
            </div>
          </div>

          <div class="col-md-9">
          </div>

        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>

          <div class="col-md-6">
          </div>

          <div class="col-md-3 text-right">
            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="perform_save_transaction">Save</button>
            <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
          </div>
        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone';
import { userService } from '@/store/auth-header.js';
import { store } from '@/store/store.js'

export default {
  name: 'HoPaymentForm',
  components: {

  },
  store,
  data () {
    return {
      readonly: false,
      ledgers: [],
      voucher: JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":0,"remarks":"","cmp_id":0,"voucher_type":{"id":0,"name":""},"ledger":{"id":1,"group_id":1,"name":"","alias":"","open_bal":0},"list":[]}'),
      detail : JSON.parse('{"idx":0,"adjust_type":0,"ledger_id":0,"remarks":"","debit":0,"credit":0}'),
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":0,"remarks":"","cmp_id":0,"voucher_type":{"id":0,"name":""},"ledger":{"id":1,"group_id":1,"name":"","alias":"","open_bal":0},"list":[]}')
    }
  },
  beforeMount () {
    this.voucher = this.myvoucher;
  },
  created () {
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD')
    this.$data.voucher.ref_date = moment().format('YYYY-MM-DD')
  },
  mounted () {
    const self = this;
    try {

      self.loadData();

      if (self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01') {
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      }
      $('#cmbledger').focus();

    }catch (e) {
      alert(e);
    }
  },
  methods: {
    indianFormat (val) {
      return parseFloat(val).toFixed(2)
    },
    closeThis () {
      this.$emit('hopayment_window_closed');
      if (this.voucher.id == 0) {
        this.$router.push('/');
      }

    },
    clear () {
      const self = this;
      self.$data.detail = JSON.parse('{"idx":0,"adjust_type":0,"ledger_id":0,"remarks":"","debit":0,"credit":0}');
      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":0,"remarks":"","cmp_id":0,"voucher_type":{"id":0,"name":""},"ledger":{"id":1,"group_id":1,"name":"","alias":"","open_bal":0},"list":[]}');
      self.$data.voucher.list = [];
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    loadData () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/holedgers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {

          if (_.isArray(resp.data)) {
            self.$data.ledgers = resp.data;
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      }).finally(()=>{
        $('#mycard').unblock();
      });

    },
    perform_save_transaction () {
      const self = this;

      try {

        if(self.voucher.ledger_id < 1) {
          $('#cmbledger').focus();
          alert('Invalid Ledger');
          return;
        }else if( parseFloat(self.voucher.amount) <= 0 ){
          $('#txtamount').focus();
          alert('Invalid Amount');
          return;
        }

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));

        myvoucher.cmp_id = self.$store.state.user.company.id;
        myvoucher.brn_id = self.$store.state.user.branch.id;
        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.vch_type = 1007;
        myvoucher.type = 2; //Debit Transaction
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.ref_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.ledger_id = parseInt(self.$data.voucher.ledger_id);
        myvoucher.amount = parseFloat(self.$data.voucher.amount);

        //Voucher Detail
        self.$data.detail.ledger_id = myvoucher.ledger_id;
        self.$data.detail.debit = parseFloat(self.$data.voucher.amount);

        myvoucher.list = [];
        myvoucher.list.push(self.$data.detail);

        const requestOptions = {
          method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        console.log(JSON.stringify(myvoucher));

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.clear();
            swal({ title: 'Success', type: 'success', text: resp.msg, onClose: () => { $('#cmbledger').focus()}, timer:1500  });
            self.$emit('hopayment_saved', resp.data);
          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error', onClose: () => { $('#cmbledger').focus()}, timer:3000  });
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error', onClose: () => { $('#cmbledger').focus()}, timer:3000  });
        }).finally(function () {
          $('#mycard').unblock();
        });


      }catch (e) {
        alert(e);
      }
    },

  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
