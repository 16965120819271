<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;HO Payment's List</h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>

          <div class="input-group-append position-static">
            <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterModal">
              <i class="icon-gear"></i>
            </button>
          </div>

        </div>
      </div>

      <div class="table-responsive">
        <table id="voucher_table"
             class="table table-bordered table-columned"
             data-search="false"
             data-pagination="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-sortable="true" data-width="75" >S.No</th>
          <th data-field="vch_no" data-width="100" data-sortable="true" data-align="center">Doc No</th>
          <th data-field="doc_date" data-width="107" data-sortable="true" data-formatter="dateFormatter" >Date</th>
          <th data-field="ledger" data-formatter="ledgerName_from_ledger" data-sortable="true">Description</th>
          <th data-field="remarks" data-sortable="true">Narration</th>
          <th data-field="amount" data-width="200" data-align="right" data-sortable="true" data-formatter="indianFormat">Amount</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <vmodal name="voucher_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="55%" :height="345" @before-open="beforeOpen" @before-close="beforeClose" >
        <HoPaymentForm v-bind:myvoucher="voucher" v-on:hopayment_saved="loadData" v-on:hopayment_window_closed="closeModal" ></HoPaymentForm>
      </vmodal>

      <FocusTrap>
        <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" :height="245" @opened="afterfilterwindow_visible" draggable=".window-header">
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Ledgers:</label>
                  <div class="col-md-9">
                    <select id="cmbLedger" class="form-control" v-model="ledger_id" >
                      <option value="0" selected>All</option>
                      <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                        {{ ledger.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>


    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import HoPaymentForm from '@/views/xpos/hopayment/HoPaymentForm.vue'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'HoPaymentView',
  components: {
    HoPaymentForm
  },
  store,
  data () {
    return {
      mytable: {},
      voucher: JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":0,"remarks":"","cmp_id":0,"voucher_type":{"id":0,"name":""},"ledger":{"id":1,"group_id":1,"name":"","alias":"","open_bal":0},"list":[]}'),
      from_date : '2000-01-01',
      upto_date : '2049-12-31',
      ledger_id : 0,
      branches: [],
      selected_ibr: 1,
      voucher_id: 0
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

    self.$data.from_date = moment().format('YYYY-MM-DD');
    self.$data.upto_date = moment().format('YYYY-MM-DD');

    self.$data.mytable = $('#voucher_table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#voucher_table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyVoucher(id);
        } else if ($(e.target).text() === 'Cancel') {
          self.cancelVoucher(id);
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#voucher_table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });

    // self.loadData();

    self.$modal.show('filter_window');
  },
  methods: {
    showModal () {
      this.$modal.show('voucher_window');
    },
    afterfilterwindow_visible () {
      this.$refs.txtfrom.focus();
    },
    beforeClose () {

    },
    showFilterModal(){
      this.$modal.show('filter_window');
      this.$refs.txtfrom.focus();
    },
    closeModal () {
      this.$modal.hide('filter_window');
      this.$modal.hide('voucher_window');
    },
    loadData () {
      let self = this;
      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('');

      this.$modal.hide('filter_window');
      this.$modal.hide('voucher_window');

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');

      // alert(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevouchers/vchtype/1005/between?from=${self.$data.from_date}&upto=${self.$data.upto_date}`);
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevouchers/vchtype/1007/between?from=${self.$data.from_date}&upto=${self.$data.upto_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $(self.$data.mytable).unblock();
      })
    },
    modifyVoucher (id) {
      const self = this
      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }


      // alert(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher/${id}`);
      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":0,"remarks":"","cmp_id":0,"voucher_type":{"id":0,"name":""},"ledger":{"id":1,"group_id":1,"name":"","alias":"","open_bal":0},"list":[]}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          self.$data.voucher = resp.data;

          // self.$data.voucher.details.forEach(function (det, i) {
          //   if (det.idx == 0) {
          //     self.$data.voucher.ledger = det.ledger
          //     self.$data.voucher.list.splice(i, 1);
          //   }
          // });

          self.$modal.show('voucher_window');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    cancelVoucher (id) {
      let self = this

      self.$data.voucher.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.voucher)
      }

      swal({
        title: 'What is the reason for cancel this voucher?',
        input: 'text',
        inputPlaceholder: 'Enter Remarks here',
        showCancelButton: true,
        inputClass: 'form-control',
        inputValidator: function (value) {
          return !value && 'You need to write something!'
        }
      }).then(function (result) {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/voucher/${id}/?remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('voucher_window');
            if (resp.ok) {
              self.loadData();
              swal({
                title: 'Canceled!',
                text: 'Your request has been processed',
                type: 'success'
              })
            } else {
              swal({ title: 'Oops', text: resp.msg, type: 'info' })
            }
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
  }
}

</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
